import SocialToolbar from "@/components/SocialToolbar/SocialToolbar";
import * as GQL from "@/gql";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import * as TUI from "theme-ui";
import { selectFromMultiLocale } from "../../../lib/intl";
import { contentPageLinkProps } from "../../../lib/links";
import { column } from "../../SectionContainer";
import { contentInfo } from "../data";

const Root = TUI.Box;

export interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  sharing?: {
    title: string;
    description: string;
  };
}

function ContentInfo(props: Props) {
  const { sx, sharing, ...rest } = props;
  const { locale } = useRouter();

  const [year, setYear] = React.useState(new Date().getFullYear());
  React.useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  if (!contentInfo) {
    return null;
  }

  return (
    <Root
      as="div"
      role="contentinfo"
      {...rest}
      sx={{
        gridColumn: column.full,
        display: "grid",
        gridTemplateColumns: ["1fr", "repeat(2, 1fr)", "repeat(4, 1fr)"],
        rowGap: 5,
        columnGap: [2, 3],
        alignItems: "center",
        ...sx,
      }}
    >
      <TUI.Box>
        <Link
          passHref
          {...contentPageLinkProps(locale, contentInfo.imprint!)}
          legacyBehavior>
          <TUI.Link variant="default" sx={{ color: "inherit", display: "block", textDecoration: "none" }}>
            <TUI.Text variant="f6_mono">
              {selectFromMultiLocale(contentInfo.imprint?._allPageNameLocales, locale)}
            </TUI.Text>
          </TUI.Link>
        </Link>
      </TUI.Box>
      <TUI.Box>
        <Link
          passHref
          {...contentPageLinkProps(locale, contentInfo.privacyPolicy!)}
          legacyBehavior>
          <TUI.Link variant="default" sx={{ color: "inherit", display: "block", textDecoration: "none" }}>
            <TUI.Text variant="f6_mono">
              {selectFromMultiLocale(contentInfo.privacyPolicy?._allPageNameLocales, locale)}
            </TUI.Text>
          </TUI.Link>
        </Link>
      </TUI.Box>

      {contentInfo.termsOfService?.__typename === "AssetLinkRecord" && (
        <TUI.Box>
          <TUI.Link
            variant="default"
            sx={{ color: "inherit", display: "block", textDecoration: "none" }}
            href={contentInfo.termsOfService?.asset?.url}
          >
            <TUI.Text variant="f6_mono">{contentInfo.termsOfService.title}</TUI.Text>
          </TUI.Link>
        </TUI.Box>
      )}

      {contentInfo.termsOfService?.__typename === "ContentPageRecord" && (
        <TUI.Box>
          <Link
            passHref
            {...contentPageLinkProps(locale, contentInfo.termsOfService!)}
            legacyBehavior>
            <TUI.Link
              variant="default"
              sx={{
                color: "inherit",
                display: "block",
                textDecoration: "none",
              }}
            >
              <TUI.Text variant="f6_mono">
                {selectFromMultiLocale(
                  contentInfo.termsOfService?._allPageNameLocales as
                    | GQL.Maybe<GQL.TranslatedFragmentFragment>[]
                    | null
                    | undefined,
                  locale
                )}
              </TUI.Text>
            </TUI.Link>
          </Link>
        </TUI.Box>
      )}

      <TUI.Link
        variant="default"
        href={contentInfo?.isoCertificate?.url}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          color: "white",
          textDecoration: "none",
        }}
      >
        <TUI.Image
          src="/icons/isolabel.svg"
          sx={{
            mr: 3,
            mt: 3,
            width: "90px",
            alignItems: "flex-end",
          }}
        />
        <TUI.Text variant="f7_mono" sx={{ alignSelf: "flex-end" }}>
          ISO 21001:2018
        </TUI.Text>
        <TUI.Text variant="f7" sx={{ width: "100%", mt: 2 }}>
          Management System for educational organizations
        </TUI.Text>
      </TUI.Link>
      <TUI.Text variant="f6" sx={{ color: "grayDark" }}>
        Copyright © {year} CYP
      </TUI.Text>

      <TUI.Box
        sx={{
          gridColumn: ["auto", "2", "4"],
        }}
      >
        <SocialToolbar />
      </TUI.Box>
    </Root>
  );
}

export default ContentInfo;
