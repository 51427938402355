import * as GQL from "@/gql";

export const contentInfo: GQL.ContentInfoQuery["contentInfo"] = {
  __typename: "ContentInfoRecord",
  imprint: {
    __typename: "ContentPageRecord",
    id: "92918145",
    native: "",
    _allNativeLocales: [
      { locale: "de", value: "" },
      { locale: "en", value: "" },
      { locale: "fr", value: "" },
      { locale: "it", value: "" },
    ],
    _allPageNameLocales: [
      { value: "Impressum", locale: "de" },
      { value: "Imprint", locale: "en" },
      { value: "Impressum", locale: "fr" },
      { value: "Impressum", locale: "it" },
    ],
  },
  privacyPolicy: {
    __typename: "ContentPageRecord",
    id: "92918146",
    native: "",
    _allNativeLocales: [
      { locale: "de", value: "" },
      { locale: "en", value: "" },
      { locale: "fr", value: "" },
      { locale: "it", value: "" },
    ],
    _allPageNameLocales: [
      { value: "Datenschutz", locale: "de" },
      { value: "Data Privacy", locale: "en" },
      { value: "Déclaration de protection des données", locale: "fr" },
      { value: "Dichiarazione sulla protezione dei dati", locale: "it" },
    ],
  },
  termsOfService: {
    __typename: "ContentPageRecord",
    id: "92918223",
    native: "",
    _allNativeLocales: [
      { locale: "de", value: "" },
      { locale: "en", value: "" },
      { locale: "fr", value: "" },
      { locale: "it", value: "" },
    ],
    _allPageNameLocales: [
      { value: "AGB", locale: "de" },
      { value: "Terms & Conditions", locale: "en" },
      { value: "Conditions générales", locale: "fr" },
      { value: "Condizioni generali", locale: "it" },
    ],
  },
  isoCertificate: {
    __typename: "FileField",
    url: "https://www.datocms-assets.com/25771/1686832461-cyp-sqs-iso-21001-2018-de230615.pdf",
  },
} as any;
