import React from "react";
import * as TUI from "theme-ui";
import { SectionContainer } from "../SectionContainer";
import { ContentInfo, Navigation } from "./internal";

const Root = TUI.Box;

export interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  compact?: boolean;
}

function Footer(props: Props, ref: React.Ref<HTMLDivElement>) {
  const { sx, compact, ...rest } = props;

  return (
    <Root
      as="footer"
      ref={ref}
      {...rest}
      sx={{ bg: "black", color: "white", mt: "auto", py: 4, ...sx }}
      data-datocms-noindex
    >
      {!compact && (
        <SectionContainer>
          <Navigation />
        </SectionContainer>
      )}

      <SectionContainer sx={{ mt: compact ? 4 : 6, mb: 4 }}>
        <ContentInfo />
      </SectionContainer>
    </Root>
  );
}

export default React.forwardRef(Footer);
