import { menuItems } from "@/cms/ContentPageStructure";
import * as Icons from "@/icons";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import * as TUI from "theme-ui";
import { contentPageLinkProps } from "../../../lib/links";
import { column } from "../../SectionContainer";

const Root = TUI.Box;

export interface Props extends React.ComponentPropsWithoutRef<typeof Root> {}

function Navigation(props: Props) {
  const { sx, ...rest } = props;
  const { locale } = useRouter();

  const footerMenuItems = useMemo(() => {
    return menuItems(locale).filter((d) => !d.isVirtual || d.children.length);
  }, [locale]);

  return (
    <Root
      as="nav"
      {...rest}
      sx={{
        gridColumn: column.full,
        display: "grid",
        gridTemplateColumns: ["1fr", "repeat(2, 1fr)", "repeat(4, 1fr)"],
        rowGap: 5,
        ...sx,
      }}
    >
      {footerMenuItems.map((d, i) => (
        <TUI.Box key={i}>
          {d.isVirtual ? (
            <TUI.Text variant="f5_mono" sx={{ py: 4 }}>
              {d.label}
            </TUI.Text>
          ) : (
            <Link passHref {...contentPageLinkProps(locale, d)} legacyBehavior>
              <TUI.Link
                variant="default"
                sx={{
                  py: 4,
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",
                  transition: "0.8s color",
                  textDecoration: "none",
                  "&:hover": {
                    transition: ".2s color",
                    opacity: 1,
                    color: "grayDark",
                  },
                }}
              >
                <TUI.Text variant="f5_mono">{d.label}</TUI.Text>
                <TUI.Box sx={{ flex: "24px 0 0", ml: 3, mb: "-1px" }}>
                  <Icons.ArrowRight24 />
                </TUI.Box>
              </TUI.Link>
            </Link>
          )}

          {d.children.map((child, j) =>
            child.isVirtual ? (
              <TUI.Text variant="f6_mono" sx={{ py: 2 }}>
                {child.label}
              </TUI.Text>
            ) : (
              <Link key={j} passHref {...contentPageLinkProps(locale, child)} legacyBehavior>
                <TUI.Link
                  variant="default"
                  sx={{
                    color: "inherit",
                    display: "block",
                    transition: "0.8s color",
                    textDecoration: "none",
                    "&:hover": {
                      transition: ".2s color",
                      opacity: 1,
                      color: "grayDark",
                    },
                  }}
                >
                  <TUI.Text variant="f6_mono" sx={{ py: 2, hyphens: "auto" }}>
                    {child.label}
                  </TUI.Text>
                </TUI.Link>
              </Link>
            )
          )}
        </TUI.Box>
      ))}
    </Root>
  );
}

export default Navigation;
