import React from "react";
import * as TUI from "theme-ui";

interface Props extends React.ComponentPropsWithoutRef<typeof TUI.Link> {
  channel: string;
  href: string;
}

function SocialButton(props: Props) {
  const { channel, href, ...rest } = props;

  const onClick = () => {
    if (typeof dataLayer !== "undefined") {
      dataLayer.push({
        event: "Socialbutton",
        channel,
      });
    }
  };

  return (
    <TUI.Link
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "36px",
        height: "36px",
        bg: "black",
        color: "white",

        "&:hover": {
          bg: "grayDark",
        },
      }}
      {...rest}
      onClick={onClick}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    />
  );
}

export default SocialButton;
